import React, { useState, useContext } from 'react'
import { Card, CardBody, CardText, CardImg, CardTitle } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { context } from '../context';

export const NewsItem = (props) => {
    const { appState, setAppState } = useContext(context);
    const [editMode, setEditMode] = useState();
    const { isAuthenticated } = useAuth0();

    const deleteItem = async () => {
        setAppState({
            ...appState,
            isLoading: true
          })
        await axios.delete(`https://ninelivesapi.jeffmcgowan.net/api/news/${props.id}`)
        setAppState({...appState, news: appState.news.filter(news => news._id !== props.id), isLoading: false})
    }
    return (
        <div className="text-dark fade-in d-flex justify-content-center news-container">
          <Card className="Card news-card">
              <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                    <CardTitle className='card-date'>{props.date}</CardTitle>
                    { isAuthenticated && <button className='btn btn-danger my-1 py-1' onClick={deleteItem}>X</button>}
                </div>
                { props.pic && 
                <div className='d-flex justify-content-center'>
                    <CardImg src={props.pic} className='news-img' /> 
                </div>
                }
                <CardTitle className='text-center'>{props.title}</CardTitle>
                  <CardText>
                    <div style={{whiteSpace: 'pre-line'}} className="w-100 news-text text-center my-3">
                      {props.news}
                    </div>
                  </CardText>
              </CardBody>
          </Card>
  
      </div>
        )
}