import React from 'react'
import { Card, CardTitle, CardBody, CardText, CardImg } from 'reactstrap';
export const Help = () => {
  return (
    <header className="content center text-dark ml-auto fade-in">
        <h1 className='mb-2'>How You Can Help</h1>
        <Card className="Card">
        <div className='card-img-container pt-2'>
            <CardImg
            className='kitty-header'
            alt="Card image cap"
            src={require('../assets/kittens2.png')}
            top
            />
        </div>

        <CardBody>
        <CardText tag='h6' className='kitty-card-text'>
            <p className="mb-1">There are many ways that you can help to support Nine Lives of Norton.</p>
            <p className="mb-1"><a href='/#/volunteers'>Become a Nine Lives of Norton volunteer</a></p>
            <p className="mb-1"><a href='/#/adoptacage'>Adopt-A-Cage Program</a></p>
            <p className="mb-3"><a href='/#/foster'>Become a foster parent</a></p>
            <p className="mb-1">How you can make donations to Nine Lives:</p>
            <p className="mb-1">Monetary Donations can be sent to:</p>
            <p className="mb-1">Nine Lives of Norton Feline Rescue Shelter INC.</p>
            <p className="mb-1">P.O. Box 548</p>
            <p className="mb-3">Norton, MA 02766</p>
            <p className="mb-1"><a href='https://www.networkforgood.org/donation/ExpressDonation.aspx?ORGID2=043433595'>Donate safely and securely online using Network for Good.</a></p>
            <div>
            <h6 className="mb-1"> Donate Items on our Wish List:</h6>

                Below is a list of items that we use on a regular basis: 
                <ul>
                    <li>Friskies Canned Cat Food</li>     
                    <li>Dry Cat Food (Purina ONE or IAMS)</li>
                    <li>Dry Kitten Food (Purina Kitten Chow)</li>
                    <li>Paper Towels</li>
                    <li>Laundry Soap </li>
                    <li>Bleach</li>
                    <li>Dish Soap </li>
                </ul>
            </div>
            <p className="mb-1">Nine Lives of Norton collects returnable cans and bottles as a fundraiser.</p>
            <p className="mb-1"><a href='http://www.ninelivesofnorton.org/ShoesWeRecycleforReuse-1.pdf'>We also collect your gently used shoes!</a></p>
            <p className="mb-1"><a href='http://www.carshelpingamerica.org/supported-charities-nine-lives.html'>Donate a car</a></p>
        </CardText>
        </CardBody>
    </Card>

    </header>
  )
}
